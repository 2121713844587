a.navbar-brand {
  white-space: normal;
  text-align: right;
  word-break: break-all;
}

html {
  font-size: 14px;
  color: white;
}
/*@media (min-width: 768px) {
    html {
        font-size: 16px;
    }

}*/


.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
