/* Provide sufficient contrast against white background */
body{
    background: #464646;
    color: #fff;
    
}
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: Background;
  border-color: #1861ac;
}

.logo{
    width: 220px;
}

.cardStyle{
   background: #464646;
}

Form{
  width: 300px;
  margin-left:50px;
}

